import React, { useState, useEffect, useMemo } from 'react';
import dashboardShareApi from 'src/api/Dashboard.share.api';
import formStore from 'src/stores/form.store';
import useBackdrop from 'src/components/Backdrop/useBackdrop';
import { Typography, Box, Grid, Tabs, Tab, Paper } from '@mui/material';
import ResumeComponent from './Resume';
import CoverLetterComponent from './CoverLetter';
import { useRequest } from 'ahooks';
import { useParams } from 'react-router-dom';

function CustomComponent(props) {
	return (
		<div>
			<Typography variant="h6">Create your own component</Typography>
			<p>{props.Test}</p>
		</div>
	);
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <>{children}</>}
		</div>
	);
}

function JobFitInsight({ isPublic }) {
	const [ready, setReady] = useState(true);
	const [BackdropComponent, showBackdrop, closeBackdrop] = useBackdrop();
	const [userInfoTab, setUserInfoTab] = useState(0);
	const [jobInfoTab, setJobInfoTab] = useState(0);

	const { jobfithash } = useParams();

	const handleUserTabChange = (event, newValue) => {
		setUserInfoTab(newValue);
	};

	const handleJobTabChange = (event, newValue) => {
		setJobInfoTab(newValue);
	};

	const { data, loading } = useRequest(() =>
		dashboardShareApi.getShareHashData(jobfithash)
	);

	const jobData = useMemo(() => data?.data || {}, [data]);

	return (
		<>
			{ready && (
				<Box>
					<Typography variant="h5" gutterBottom>
						Job Fit Insight
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Paper
								sx={{
									p: 2,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
								}}
							>
								<Tabs
									value={userInfoTab}
									onChange={handleUserTabChange}
									aria-label="Job Seeker's Information Tabs"
								>
									<Tab label="Resume" />
									<Tab label="Coverletter" />
								</Tabs>
								<TabPanel value={userInfoTab} index={0}>
									{/* <CustomComponent Test="Resume Component" /> */}
									<ResumeComponent
										jobId={jobData?.jobId}
										jobData={jobData?.job}
									/>
								</TabPanel>
								<TabPanel value={userInfoTab} index={1}>
									<CoverLetterComponent
										coverLetterData={jobData?.coverLetter}
										jobData={jobData?.job}
									/>
								</TabPanel>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Tabs
								value={jobInfoTab}
								onChange={handleJobTabChange}
								aria-label="Job Information Tabs"
							>
								<Tab label="Job Post" />
								<Tab label="Job Description" />
								<Tab label="User Dashboard" />
							</Tabs>
							<TabPanel value={jobInfoTab} index={0}>
								<CustomComponent Test="Job ApplyURL Page" />
							</TabPanel>
							<TabPanel value={jobInfoTab} index={1}>
								<CustomComponent Test="Job Description" />
							</TabPanel>
							<TabPanel value={jobInfoTab} index={2}>
								<CustomComponent Test="User Dashboard" />
							</TabPanel>
						</Grid>
					</Grid>
				</Box>
			)}
			<BackdropComponent />
		</>
	);
}

export default JobFitInsight;
